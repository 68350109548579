
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;300;400;500;700&display=swap');

*{
    margin:0;
    padding: 0;
    border:0;
}

body , button , input{
    font-family: 'Josefin Sans', sans-serif;
}

body{
    max-height: 100vh;
    max-width: 100vw;
    overflow: hidden;
}